/*
 * Side nav Messages
 *
 * This contains all the text for the Side nav container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.MainNav';

export default defineMessages({
  aboutKamereo: {
    id: `${scope}.aboutKamereo`,
    defaultMessage: 'Về Kamereo',
  },
  blog: {
    id: `${scope}.blog`,
    defaultMessage: 'Blog',
  },
  callUs: {
    id: `${scope}.callUs`,
    defaultMessage: 'Gọi chúng tôi',
  },
  changeStore: {
    id: `${scope}.changeStore`,
    defaultMessage: 'Thay đổi chi nhánh',
  },
  change: {
    id: `${scope}.change`,
    defaultMessage: 'Thay đổi',
  },
  downloadKamereoApp: {
    id: `${scope}.downloadKamereoApp`,
    defaultMessage: 'Tải ứng dụng Kamereo',
  },
  favoriteItems: {
    id: `${scope}.favoriteItems`,
    defaultMessage: 'Yêu thích',
  },
  overview: {
    id: `${scope}.overview`,
    defaultMessage: 'Báo cáo tổng quan',
  },
  orders: {
    id: `${scope}.orders`,
    defaultMessage: 'Đơn hàng',
  },
  suppliers: {
    id: `${scope}.suppliers`,
    defaultMessage: 'Nhà cung cấp',
  },
  marketPlace: {
    id: `${scope}.marketPlace`,
    defaultMessage: 'Chợ',
  },
  mart: {
    id: `${scope}.mart`,
    defaultMessage: 'Chợ',
  },
  analysis: {
    id: `${scope}.analysis`,
    defaultMessage: 'Chợ', // TODO
  },
  supplierAnalysis: {
    id: `${scope}.supplierAnalysis`,
    defaultMessage: 'Chợ', // TODO
  },
  itemAnalysis: {
    id: `${scope}.itemAnalysis`,
    defaultMessage: 'Chợ', // TODO
  },
  companyInfo: {
    id: `${scope}.companyInfo`,
    defaultMessage: 'Công ty',
  },
  scanQRorDownloadApp: {
    id: `${scope}.scanQRorDownloadApp`,
    defaultMessage: 'Quét mã QR hoặc tải App:',
  },
  storeInfo: {
    id: `${scope}.storeInfo`,
    defaultMessage: 'Chi nhánh',
  },
  usersAndGroup: {
    id: `${scope}.usersAndGroup`,
    defaultMessage: 'Đồng nghiệp & nhóm',
  },
  groups: {
    id: `${scope}.groups`,
    defaultMessage: 'Nhóm',
  },
  usersManagement: {
    id: `${scope}.usersManagement`,
    defaultMessage: 'Đồng nghiệp',
  },
  orderHistory: {
    id: `${scope}.orderHistory`,
    defaultMessage: 'Lịch sử đơn hàng',
  },
  pendingApproval: {
    id: `${scope}.pendingApproval`,
    defaultMessage: 'Đang chờ duyệt',
  },
  pendingOrdersHistory: {
    id: `${scope}.pendingOrdersHistory`,
    defaultMessage: 'Lịch sử đơn chờ duyệt',
  },
  search: {
    id: `${scope}.search`,
    defaultMessage: 'Tìm',
  },
  loginToSwitchStore: {
    id: `${scope}.loginToSwitchStore`,
    defaultMessage: 'Đăng nhập để chọn cửa hàng',
  },
  accountSetting: {
    id: `${scope}.accountSetting`,
    defaultMessage: 'Thiết lập tài khoản',
  },
  signOut: {
    id: `${scope}.signOut`,
    defaultMessage: 'Đăng xuất',
  },
  ownerAccount: {
    id: `${scope}.ownerAccount`,
    defaultMessage: 'Tài khoản của ',
  },
  home: {
    id: `${scope}.home`,
    defaultMessage: 'Trang Chủ',
  },
  aboutUs: {
    id: `${scope}.aboutUs`,
    defaultMessage: 'Về Công Ty',
  },
  careers: {
    id: `${scope}.careers`,
    defaultMessage: 'Tuyển Dụng',
  },
  paymentMethods: {
    id: `${scope}.paymentMethods`,
    defaultMessage: 'Phương thức thanh toán',
  },
  deliveryPolicy: {
    id: `${scope}.deliveryPolicy`,
    defaultMessage: 'Chính sách giao hàng',
  },
  exchangeAndReturnPolicy: {
    id: `${scope}.exchangeAndReturnPolicy`,
    defaultMessage: 'Chính sách đổi trả hàng',
  },
  language: {
    id: `${scope}.language`,
    defaultMessage: 'Ngôn ngữ',
  },
  downloadApp: {
    id: `${scope}.downloadApp`,
    defaultMessage: 'Tải ứng dụng',
  },
  doYouNeedHelp: {
    id: `${scope}.doYouNeedHelp`,
    defaultMessage: 'Bạn cần sự giúp đỡ?',
  },
  followUsOn: {
    id: `${scope}.followUsOn`,
    defaultMessage: 'Kết nối với chúng tôi',
  },
  signUp: {
    id: `${scope}.signUp`,
    defaultMessage: 'Đăng ký',
  },
  report: {
    id: `${scope}.report`,
    defaultMessage: 'Báo cáo',
  },
  overviewReport: {
    id: `${scope}.overviewReport`,
    defaultMessage: 'Báo cáo tổng quan',
  },
  itemsAnalysis: {
    id: `${scope}.itemsAnalysis`,
    defaultMessage: 'Thống kê theo sản phẩm',
  },
  userManagement: {
    id: `${scope}.userManagement`,
    defaultMessage: 'Người dùng',
  },
  groupManagement: {
    id: `${scope}.groupManagement`,
    defaultMessage: 'Nhóm',
  },
  popularSearches: {
    id: `${scope}.popularSearches`,
    defaultMessage: 'Tìm kiếm phổ biến',
  },
  businessTips: {
    id: `${scope}.businessTips`,
    defaultMessage: 'Kinh Nghiệm Kinh Doanh F&B',
  },
  foodAndLifeStyle: {
    id: `${scope}.foodAndLifeStyle`,
    defaultMessage: 'Ẩm Thực Và Đời Sống',
  },
  topPlaces: {
    id: `${scope}.topPlaces`,
    defaultMessage: 'Top Địa Điểm',
  },
  kamereoNews: {
    id: `${scope}.kamereoNews`,
    defaultMessage: 'Tin Tức Kamereo',
  },
  sharingConner: {
    id: `${scope}.sharingConner`,
    defaultMessage: 'Góc chia sẻ',
  },
});
